/* MediaLightbox.css */
.media-lightbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.lightbox-media {
  max-height: 80vh; /* Limit the media height to 80% of the viewport height */
  max-width: 80vw;  /* Limit the media width to 80% of the viewport width */
}



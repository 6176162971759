.tooltip {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1000; /* Ensure the tooltip has a high z-index */
  top: 100%; /* Position the tooltip directly below the button */
  left: 20%; /* Move the tooltip slightly to the left */
  transform: translateX(-70%); /* Adjust the centering */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.listing-detail-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
  padding: 2rem;
  border-radius: 1rem;
}

.listing-content {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
  border-radius: 1rem;
  overflow: hidden;
}

.listing-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(31, 41, 55, 0.9), rgba(31, 41, 55, 0.6), rgba(31, 41, 55, 0.3));
}

.listing-header, .listing-footer {
  display: flex;
  padding: 1rem;
}

.listing-header {
  position: absolute;
  top: 0;
  width: 100%;
}

.listing-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.tab-container {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid #4b5563;
  overflow-x: auto;
}

.tab-container button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: #9ca3af;
  cursor: pointer;
  transition: color 0.3s;
}

.tab-container button:hover, .tab-container button.active {
  color: #60a5fa;
  border-bottom: 2px solid #60a5fa;
}

.tab-content {
  padding: 1rem;
}

/* Spinner styling */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Modal styling */
.modal-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1f2937;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  color: white;
}

/* Adjust modal width for mobile */
@media (max-width: 768px) {
  .modal-content {
    width: 70%; /* Adjust width to 90% of the viewport width */
    padding: 1rem; /* Reduce padding for mobile */
  }
  .modal-backdrop, .modal-overlay { 
   justify-content: left;
}
}

/* New styles for the transparent div */
.transparent-div {
  background-color: rgba(0, 0, 0, 0.7); /* Less transparent */
  padding: 1rem;
  border-radius: 2rem;
  border: 1px solid #4b5563;
  text-align: left;
}

.delivery-time {
  margin-top: 0.5rem; /* Add some margin between the elements */
}

/* General button styling */
.send-message-button,
.select-package-button {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20rem; /* Match the circular radius */
  cursor: pointer;
  background-color: #4B4B4B;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: inset 0 -2px 4px 0 rgba(255, 255, 255, 0.64), inset 0 2px 4px 0 rgba(255, 255, 255, 0.24);
  text-align: center;
  display: block;
  margin: 0 auto;
  opacity: 1;
  backdrop-filter: blur(12px);
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

/* Hover effect for buttons */
.send-message-button:hover,
.select-package-button:hover {
  background-color: #8c8c8c; /* Darker shade on hover */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), inset 0 -2px 4px 0 rgba(255, 255, 255, 0.64), inset 0 2px 4px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Button styling for when a package is selected */
.select-package-button.selected {
  background-color: #0056b3; /* Darker shade for selected button */
}

/* Styling for pay-for-listing button */
.pay-forlisting-button {
  padding: 10px 20px;
  border: none;
  background: linear-gradient(135deg, #FF6347, #4a5568) !important;
  color: #ffffff; /* White text */
  cursor: pointer;
  border-radius: 0.25rem; /* Add rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
margin: 0 auto;
}

/* Hover effect for pay-for-listing button */
.pay-forlisting-button:hover {
  background-color: #FF4500; /* Darker shade on hover */
  box-shadow: 0 0 10px rgba(255, 140, 0, 0.8), inset 0 -2px 4px 0 rgba(255, 140, 0, 0.64), inset 0 2px 4px 0 rgba(255, 140, 0, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}

@media (max-width: 768px) {
  .listing-detail-container {
    flex-direction: column;
  }

  .tab-container {
    margin-top: 0.5rem;
  }

  .listing-content {
    height: 40vh; /* Adjust the height as needed for mobile */
  }

  .top-left-info {
    position: static;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .listing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.package-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  background-color: #2d3748; /* Dark gray background */
}

.package-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6); /* Slightly darker shadow */
}

.package-item.selected {
  background-color: #1a202c; /* Slightly darker gray for selected package */
  border: 2px solid #ffffff; /* White border for the selected package */
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.3); /* White glow effect */
}

.package-item .font-semibold {
  font-size: 1.25rem;
  color: #ffffff; /* White text for package title */
}

.package-item p {
  font-size: 1rem;
  color: #d1d5db; /* Light gray text for descriptions */
}

.select-package-button {
  background-color: #1a202c; /* Dark gray button background */
  color: #ffffff; /* White text */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.select-package-button:hover {
  background-color: #ffffff; /* White background on hover */
  color: #1a202c; /* Dark text on hover */
}


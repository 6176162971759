.medal {
  border: 1px solid #444;
  padding: 12px;
  margin: 8px auto; /* Center the medal and avoid taking the full width */
  border-radius: 12px;
  display: flex;
  align-items: center;
  max-width: 400px; /* Limit the width of the medal card */
  background: linear-gradient(135deg, #2b2b2b, #1c1c1c);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, background 0.3s ease;
}

.medal:hover {
  transform: translateY(-5px); /* Lift the medal on hover */
  background: linear-gradient(135deg, #3b3b3b, #2b2b2b);
}

.medal.achieved {
  background: linear-gradient(135deg, #4a90e2, #007aff); /* Gradient for achieved medals */
  color: white;
}

.medal-icon {
  margin-right: 16px;
  font-size: 36px; /* Larger icon for better emphasis */
  color: #ffa500; /* Orange color for icons */
}

.medal.achieved .medal-icon {
  color: #ffd700; /* Bright gold for achieved medals */
}

.medal-details {
  flex-grow: 1;
  color: #eaeaea; /* Light text color */
}

.medal-details h3 {
  margin-bottom: 8px;
  font-size: 20px;
}

.medal-details p {
  margin-bottom: 4px;
  font-size: 14px;
  color: #b0b0b0;
}

.progress-bar {
  background-color: #333;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
  height: 8px;
}

.progress {
  background-color: #4caf50;
  height: 100%;
  transition: width 0.3s ease;
}

.progress-text {
  text-align: right;
  font-size: 12px;
  margin-top: 4px;
  color: #ccc;
}

.medal.achieved .progress {
  background-color: #ffd700; /* Match the progress bar color with the achieved medal color */
}


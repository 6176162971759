/* RemoveListing.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #2d2d2d;
  color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.confirmation-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-message {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.confirmation-buttons {
  display: flex;
  gap: 10px;
}

.confirm-button {
  background-color: #f56565; /* Red color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirm-button:hover {
  background-color: #e53e3e;
}

.cancel-button {
  background-color: #4a5568; /* Dark gray */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #2d3748;
}


/* src/components/disputes/DisputeCenter.css */

.dispute-center {
  padding: 20px;
  background: linear-gradient(to bottom, #2d3748, #1a202c, #000000);
  color: #f5f5f5; /* Light text for readability */
  border-radius: 0.5rem; /* Rounded corners */
}

.dispute-sort {
  margin-bottom: 20px;
}

.dispute-sort label {
  color: #f5f5f5;
  margin-right: 10px;
}

.dispute-sort select {
  padding: 5px;
  border-radius: 0.25rem;
  background-color: #1f2937; /* Darker background to match the new design */
  color: #f5f5f5;
  border: 1px solid #4a5568;
}

.dispute-card {
  background-color: #1e293b; /* This could be kept or adjusted for a consistent look */
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.dispute-card:hover {
  transform: scale(1.02);
}

.hustlesmall-button {
  background-color: #374151; /* Updated background color to match the button in the design */
  color: #ffffff;
  padding: 10px;
  border-radius: 0.5rem;
  text-align: center;
  display: block;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove underline from links */
  border: none; /* Remove border */
}

.hustlesmall-button:hover {
  background-color: #374151; /* Remove hover effect by keeping the same color */
}

.hustlesmall-button:active {
  background-color: #4b5563; /* Slightly darker color on click to show interaction */
}

/* Tab Navigation Styling */
.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  background-color: #1a202c;
  color: #f5f5f5;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #2563eb; /* Active tab background */
  color: #ffffff; /* Active tab text color */
}

.tab-button:hover {
  background-color: #3b82f6; /* Hover effect for tabs */
}



/* src/components/orders/freelancerOrderManagement/OrderCard.css */
.order-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order-card {
  display: flex;
  align-items: flex-start;
  border: 1px solid #dbe2e8;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  background-color: #1e293b; /* Adjusted background color for better contrast */
  color: #f5f5f5; /* Adjusted text color for better readability */
  transition: box-shadow 0.3s;
  width: 48%; /* Adjust width to fit two cards per row with some gap */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  min-height: 200px; /* Increase the height of the card */
}

.order-card-thumbnail {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-right: 1rem; /* Add space between the image and the content */
}

.order-card-content {
  flex: 1; /* Ensure the content takes the remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-card-content h3 {
  margin-top: 0;
  font-size: 1.1rem; /* Increase font size for better readability */
}

.order-card-content p {
  margin: 0.5rem 0;
  font-size: 0.9rem; /* Increase font size for better readability */
}

.order-card-content .status {
  margin-top: auto;
}

.order-card button {
  background: linear-gradient(135deg, #2d3748, #4a5568) !important;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.order-card button:hover {
  background-color: #2563eb;
}


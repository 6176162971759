.bg-grad-cat {
  background: linear-gradient(135deg, #2d3748, #4a5568) !important;
}

.bg-grad-cat:hover {
  background: linear-gradient(135deg, #4a5568, #2d3748);
}

.hover\:border-animate:hover {
  border-image: linear-gradient(to right, #4a5568, #2d3748) 1;
  animation: borderMove 2s infinite;
}
@keyframes borderMove {
  0% { border-width: 2px; }
  50% { border-width: 4px; }
  100% { border-width: 2px; }
}

.subcategory-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75); /* Darken the background */
  z-index: 50;
  padding: 24px;
}

.modal-content {
  background-color: #2d3748; /* Darker background for the modal */
  padding: 32px;
  border-radius: 16px; /* More rounded corners */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5); /* Stronger shadow for depth */
  max-width: 600px;
  width: 100%;
  animation: fadeIn 0.3s ease; /* Smooth fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  color: #f7fafc; /* Light text for contrast */
  border-bottom: 2px solid #4a5568; /* Subtle underline */
  padding-bottom: 12px;
}

.medals-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

@media (min-width: 640px) {
  .medals-grid {
    grid-template-columns: 1fr 1fr; /* Two columns on small screens and up */
  }
}

@media (min-width: 768px) {
  .medals-grid {
    grid-template-columns: repeat(2, 1fr); /* Adjust this for larger screens if needed */
  }
}

.subcategory-option {
  background-color: #4a5568;
  color: #fff;
  padding: 16px; /* More padding for better spacing */
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.subcategory-option:hover {
  background-color: #5a6478;
  transform: translateY(-4px); /* Slight lift on hover */
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #4a5568;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #5a6478;
}


.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.category-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default to two columns on mobile */
  gap: 16px;
  padding: 16px;
}

@media (min-width: 640px) {
  .category-container {
    grid-template-columns: repeat(3, 1fr); /* Three columns on small screens (640px and up) */
  }
}

@media (min-width: 768px) {
  .category-container {
    grid-template-columns: repeat(4, 1fr); /* Four columns on medium screens (768px and up) */
  }
}

.category-card {
  color: #fff;
  padding: 16px;
  cursor: pointer; /* Add cursor pointer for clickable effect */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}


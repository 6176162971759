/* General button styling */
.sendmessage-button {
  z-index: 1;
  box-shadow: inset 0 -2px 4px 0 rgba(255, 255, 255, 0.64), inset 0 2px 4px 0 rgba(255, 255, 255, 0.24);
  opacity: 1;
  backdrop-filter: blur(12px);
  color: var(--text, white); /* Fallback to white if var(--text) is not defined */
  background-color: transparent;
  border: 2px rgba(255, 255, 255, 0.08);
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  width: 70%;
}

/* Hover effect for buttons */
.sendmessage-button:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), inset 0 -2px 4px 0 rgba(255, 255, 255, 0.64), inset 0 2px 4px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}


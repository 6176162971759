.medals-page {
  padding: 140px;
  background-color: #121212; /* Dark background to match the rest of the UI */
  color: #eaeaea;
}

.status-bar {
  font-size: 18px;
  margin-bottom: 24px;
  text-align: center;
  color: #b0b0b0; /* Subtle text color */
}

.medals-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Flexible layout */
  gap: 24px; /* Increased gap for better spacing */
  justify-content: center; /* Center the grid content */
}

.medal-header {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffa500; /* Orange header color */
  text-align: center;
}

/* Responsive design adjustments */
@media (max-width: 1024px) {
  .medals-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }

  .medal-header {
    font-size: 20px; /* Slightly smaller headers */
  }
}

@media (max-width: 768px) {
  .medals-grid {
    grid-template-columns: repeat(1, 1fr); /* Single column on mobile */
  }

  .medal-header {
    font-size: 18px;
  }
}


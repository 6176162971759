.referral-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.referral-story {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media (min-width: 768px) {
  .referral-story {
    grid-template-columns: repeat(3, 1fr);
  }
}

.story-card {
  background-color: #1a1a1a; /* Dark background */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.generate-link-section {
  text-align: center;
}

.generate-link-btn {
  background-color: #2563eb;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-link-btn:hover {
  background-color: #1d4ed8;
}

.referral-link {
  background-color: #3b3b3b;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.copy-link-btn {
  display: flex;
  align-items: center;
  background-color: #10b981;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-link-btn:hover {
  background-color: #059669;
}


.delete-message-container {
  display: inline-block;
  margin-left: 8px;
  float: right;
  position: relative;
  top: -5px;
}


.trash-can-icon {
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

.confirm-delete-text {
  color: red;
}

.delete-icon {
  cursor: pointer;
  color: #ff0000;
}

.deleted-message {
  color: #ff0000;
}

.hidden {
  display: none;
}

.thumbnail {
  cursor: pointer;
  display: inline-block;
}

.thumbnail-image, .thumbnail-video, .thumbnail-audio, .thumbnail-icon {
  width: 100px; /* Adjust size as needed */
  height: 100px;
  object-fit: cover;
}

.file-preview .preview-image-small, .file-preview .preview-video-small {
  width: 50px; /* Smaller preview before upload */
  height: 50px;
  object-fit: cover;
  margin: 10px;
}

.file-preview .preview-video-small {
  display: block;
}


.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: #ffffff; /* White text color */
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  border-top: 1px solid #444; /* Divider above the input */
}

.search-bar {
  margin-bottom: 16px;
}

.search-bar input {
  background: linear-gradient(135deg, #2d3748, #4a5568);
  color: #fff;
  border: none;
}

.messages-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.messages-list .border {
  border-color: #444;
  background-color: #2c3e50; /* Match the messages container background */
}

.message-form {
  padding: 16px;
  border-top: 1px solid #444; /* Divider line */
}

.input-container {
  display: flex;
  align-items: center;
}

.message-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background: linear-gradient(135deg, #2d3748, #4a5568);
  color: #fff;
}

.file-upload-label {
  margin-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.file-upload-label .fa-paperclip {
  font-size: 1.5rem;
  color: #fff;
}

.file-input {
  display: none; /* Hide the file input */
}

.send-button {
  margin-left: 16px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.file-preview {
  margin-top: 16px;
  border-top: 1px solid #444;
  padding-top: 16px;
}

.lightbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.lightbox-content {
  max-width: 80%;
  max-height: 80%;
  background-color: #1a1a1a;
  padding: 16px;
  border-radius: 8px;
}

.lightbox-image,
.lightbox-video {
  max-width: 100%;
  max-height: 100%;
}

.lightbox-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

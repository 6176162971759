@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@300;400;600&display=swap');


:root {
  --primary-color: #1a1a1a; /* Dark background color */
  --secondary-color: #282c34; /* Header background color */
  --text-color: white; /* Default text color */
  --accent-color: #61dafb; /* Accent color for links and highlights */
  --error-color: #ff4d4f; /* Error messages color */
  --font-size-base: 16px; /* Base font size */
  --font-size-large: 1.25rem; /* Large text size */
  --font-size-small: 0.875rem; /* Small text size */
  --spacing-small: 8px; /* Small spacing */
  --spacing-medium: 16px; /* Medium spacing */
  --spacing-large: 24px; /* Large spacing */
  --font-primary: 'Bebas Neue', cursive; /* For headings */  
--font-secondary: 'Poppins', sans-serif; /* For body text */
}

/* Headings use Anton */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary) !important;
}

/* Body text uses Roboto */
body, p, span, a, li {
  font-family: var(--font-secondary) !important;
}

.bg-custom-dark {
  background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%);
  opacity: 1; /* Ensures full opacity */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--secondary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
}

.App-link {
  color: var(--accent-color);
}

/* Apply font globally to all text elements in Footer */
.footer-font h1,
.footer-font h2,
.footer-font h3,
.footer-font h4,
.footer-font h5,
.footer-font h6,
.footer-font p,
.footer-font span,
.footer-font a,
.footer-font li {
  font-family: var(--font-primary) !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header-logo {
  max-height: 50px; /* Adjust the height as needed */
  width: auto;
  max-width: 100%;
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a202c; /* bg-gray-800 */
  color: #fff; /* text-white */
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  padding-right: 1em;
}

.menu-dropdown {
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 12rem;
  background-color: #2d3748; /* Dark background for dropdowns */
  color: #fff; /* White text for dropdowns */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  z-index: 50;
  overflow-y: auto;
  max-height: 20rem;
}

 .messages-dropdown, .notifications-dropdown {
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 18rem;
  background-color: #2d3748; /* Dark background for dropdowns */
  color: #fff; /* White text for dropdowns */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  z-index: 50;
  overflow-y: auto;
  max-height: 20rem;
}


.menu-button, .messages-button, .notifications-button {
  background-color: transparent; /* Make the buttons transparent */
  border: none; /* Remove default button borders */
  color: white; /* White text color */
  border-radius: 0.375rem; /* rounded-md */
  //padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.menu-button:hover, .messages-button:hover, .notifications-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent white background on hover */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Glow effect on hover */
}

/* Add new styles for the smaller button */
.solana-connect-button {
  padding: 0.25rem 0.5rem; /* Smaller padding */
  font-size: 0.875rem; /* Smaller font size */
  background-color: transparent; /* Make the button transparent */
  color: #fff; /* Button text color */
  border: none; /* Remove default button borders */
  border-radius: 0.25rem; /* Small border radius */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.solana-connect-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent white background on hover */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Glow effect on hover */
}

.flex-right {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

@media (max-width: 767px) {
  .header-nav {
    align-items: center;
  }

  .header-nav {
    width: 100%;
    display: flex;
    justify-content: end;
  }
   .menu-dropdown, .messages-dropdown, .notifications-dropdown {
    left: 5%;
  transform: translateX(-50%) translateY(40px); /* Center the tooltip horizontally and add a bit of vertical spacing */

}
}

@media (max-width: 767px) {
  .notifications-button .block,
  .messages-button .block {
    top: -2px; /* Adjust for better alignment */
    right: 0px; /* Adjust to prevent overlap with adjacent icons */
    height: 6px; /* Slightly smaller on mobile */
    width: 6px;  /* Slightly smaller on mobile */
  }
}

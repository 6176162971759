.privacy-root {
  background-color: #2C3E50;
  color: #ECF0F1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.privacy-container {
  background-color: #34495E;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.privacy-header {
  margin-bottom: 20px;
}

.privacy-header h1 {
  margin: 0;
}

.privacy-section {
  margin-bottom: 20px;
}

.privacy-section h2 {
  margin-bottom: 10px;
}

.privacy-section ul {
  list-style-type: none;
  padding-left: 20px;
}

.privacy-section ul ul {
  list-style-type: disc;
}


.contact-us {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact-us h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-us p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.contact-us form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us input,
.contact-us textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-us button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-us button:hover {
  background-color: #0056b3;
}


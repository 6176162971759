.upload-profile-media {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.upload-section {
  display: flex;
  flex-direction: column;
}

.upload-label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.upload-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 2px dashed #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.upload-input {
  display: none;
}

.thumbnail-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail {
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
}


.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dark-modal {
  background: #1f1f1f;
  color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dark-modal h2 {
  margin-top: 0;
}

.dark-modal-content {
  margin-top: 20px;
}

.close-button {
  background: transparent;
  border: none;
  color: #f1f1f1;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}


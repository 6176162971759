/* Login.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #1a202c;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  width: 90%;
  text-align: center;
  color: #ffffff;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
}

/* General button styling */
.loginsmall-button {
  padding: 10px 20px;
  border: none;
  background: linear-gradient(135deg, #2d3748, #4a5568) !important;
  color: #ffffff; /* White text */
  cursor: pointer;
  border-radius: 0.25rem; /* Add rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

/* Hover effect for buttons */
.loginsmall-button:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}


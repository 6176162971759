/* faq.css */
.faq {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  color: #d1d5db;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.question {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 15px 0;
  transition: color 0.3s;
  color: #9ca3af;
}

.question:hover {
  color: #60a5fa;
}

.answer {
  display: none;
  font-size: 1rem;
  margin-top: 10px;
  line-height: 1.5;
  color: #ffffff;
}

.question.active + .answer {
  display: block;
}


/* EditModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1f2937;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 500px;
  width: 100%;
}

.save-button, .cancel-button {
  background: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
}

.save-button:hover, .cancel-button:hover {
  background: #1d4ed8;
}


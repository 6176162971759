/* General button styling */
.payment-button {
  z-index: 1;
  box-shadow: inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  opacity: 1;
  backdrop-filter: blur(12px);
  color: var(--text, white); /* Fallback to white if var(--text) is not defined */
  background-color: transparent;
  border: 1px rgba(255, 255, 255, 0.08);
  border-radius: 10rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.875rem; /* Reduce font size */
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
}

/* Hover effect for buttons */
.payment-button:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Paylist button styling */
.paylist-button {
  z-index: 1;
  box-shadow: inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  opacity: 1;
  backdrop-filter: blur(12px);
  color: var(--text, white); /* Fallback to white if var(--text) is not defined */
  background-color: gray;
  border: 1px rgba(255, 255, 255, 0.08);
  border-radius: 10rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.875rem; /* Reduce font size */
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
}

/* Hover effect for paylist button */
.paylist-button:hover {
  background-color: lightgray; /* Lighter green on hover */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* src/components/orders/Orders.css */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background: linear-gradient(135deg, #2d3748, #4a5568) !important;
  color: #ffffff; /* White text */
  cursor: pointer;
  border-radius: 0.25rem; /* Add rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.tabs button:hover {
  background-color: #2563eb; /* Darker blue on hover */
}

.tabs button.active {
  background: #1e40af; /* Even darker blue for the active state */
  color: #ffffff; /* Ensure text remains white */
}

.tab-content {
  padding: 20px;
  background: linear-gradient(to bottom, #2d3748, #1a202c, #000000);
  color: #f5f5f5; /* Adjust text color for readability */
  border-radius: 0.5rem; /* Rounded corners for the content area */
}


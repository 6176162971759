/* General button styling */
.hustlesmall-button {
  padding: 10px 20px;
  border: none;
  background: linear-gradient(135deg, #2d3748, #4a5568) !important;
  color: #ffffff; /* White text */
  cursor: pointer;
  border-radius: 0.25rem; /* Add rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
margin: 0 auto;
}

/* Hover effect for buttons */
.hustlesmall-button:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}


/* Grid container styling */
.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

/* Full width field within the grid */
.full-width {
  grid-column: span 2;
}

/* General button styling */
.hustle-button {
  z-index: 1;
  box-shadow: inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  opacity: 1;
  backdrop-filter: blur(12px);
  color: var(--text, white); /* Fallback to white if var(--text) is not defined */
  background-color: transparent;
  border: 1px rgba(255, 255, 255, 0.08);
  border-radius: 10rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.875rem; /* Reduce font size */
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  width: 30%; /* Adjust width as needed */
}

/* Hover effect for buttons */
.hustle-button:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Input and textarea styling */
.input-field, .textarea-field, .select-field__control {
  width: 100%;
  padding: 0.5rem; /* Reduce padding */
  border-radius: 0.5rem; /* Match button border-radius */
  background-color: transparent;
  font-size: 16px;
  border: 1px rgba(255, 255, 255, 0.08);
  color: white;
  backdrop-filter: blur(12px);
  box-shadow: inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  margin-bottom: 0.5rem; /* Reduce margin */
  transition: all 0.3s;
  font-size: 0.875rem; /* Reduce font size */
}

.input-field:hover, .textarea-field:hover, .select-field__control:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05);
}

 .input-field-2 {
  width: 60%;
  padding: 0.5rem; /* Reduce padding */
  border-radius: 0.5rem; /* Match button border-radius */
  background-color: transparent;
  font-size: 16px;
  border: 1px rgba(255, 255, 255, 0.08);
  color: white;
  backdrop-filter: blur(12px);
  box-shadow: inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  margin-bottom: 0.5rem; /* Reduce margin */
  transition: all 0.3s;
  font-size: 0.875rem; /* Reduce font size */
}

.input-field-2:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.64), inset 0 1px 2px 0 rgba(255, 255, 255, 0.24);
  transform: scale(1.05);
}


.textarea-field {
  min-height: 50px; /* Reduce min-height */
  resize: vertical;
}

/* Container for input and select fields */
.field-container {
  margin-bottom: 1rem; /* Reduce margin */
}

/* Label styling */
.label {
  display: block;
  font-size: 0.75rem; /* Reduce font size */
  font-weight: 600;
  margin-bottom: 0.25rem; /* Reduce margin */
}

/* Flex container for button alignment */
.flex-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem; /* Reduce margin */
}

/* Success and error messages */
.text-red-500 {
  font-size: 0.875rem; /* Reduce font size */
}

.text-green-500 {
  font-size: 0.875rem; /* Reduce font size */
}


.verify-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f59e0b;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}



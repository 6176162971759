
.lock-scroll {
  overflow: hidden !important;
}
 
.p-tooltip {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.p-tooltip .p-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1000; /* Make sure the tooltip has a high z-index */
  bottom: 125%; /* Adjust this value to position the tooltip */
  left: 20%; /* Move tooltip to the left */
  margin-left: -90px; /* Adjust the margin to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.p-tooltip:hover .p-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.profile-info-container {
  position: relative;
  margin-top: 0px; /* Adjust as necessary to move it under the banner */
}

.profile-info-dark-bg {
  z-index: 1;
  padding: 15px;
  border-radius: 0 0 10px 10px;
}

.profile-avatar-container {
  position: absolute;
  top: -3rem; /* Adjust to center the avatar over the dark div */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* Ensure the avatar is on top */
}

.profile-avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 4px solid white; /* Optional border */
}

.profile-name-container {
  margin-top: 30px; /* Adjust to provide space between the avatar and text */
}

.profile-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.profile-headline {
  font-size: 1rem;
  color: #ccc;
  margin-top: 5px;
}

.profile-banner {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #1f2937;
    overflow: hidden;
}

.profile-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

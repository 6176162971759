/* DisputePage.css */

/* Center the top section with dispute details */
.dispute-details {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #2d3748;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style the headers within dispute details */
.dispute-details h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #ffffff;
}

.dispute-details p {
  margin: 5px 0;
  color: #f5f5f5;
}

/* Style the response log */
.response-log {
  margin-top: 20px;
  width: 80%; /* Adjust width */
  margin-left: auto;
  margin-right: auto;
}

/* Individual response entry styling */
.response-entry {
  background-color: #1a202c;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.response-entry p {
  margin: 5px 0;
  color: #e2e8f0;
}

/* Add a message above the response text area */
.response-message {
  text-align: center;
  font-size: 1rem;
  color: #f5f5f5;
  margin-bottom: 10px;
}

/* Response text and button */
.response-input-container {
  position: relative;
  width: 60%; /* Reduce width for better centering */
  margin: 20px auto; /* Center with margin auto */
}

.response-textarea {
  width: 100%;
  padding: 10px 40px 10px 10px; /* Add padding to the right for the icon */
  border-radius: 0.5rem;
  border: 1px solid #4a5568;
  background-color: #1a202c;
  color: #ffffff;
}

/* File upload icon container */
.file-upload-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  cursor: pointer;
}

.file-upload-label {
  cursor: pointer;
}

.file-upload-icon {
  font-size: 1.5rem;
  color: #ffffff;
}

.file-upload-input {
  display: none;
}

/* Style for file preview and remove button */
.selected-files {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.file-preview {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #2d3748;
  padding: 5px 10px;
  border-radius: 0.5rem;
  color: #ffffff;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.file-preview span {
  margin-right: 10px;
  font-size: 0.9rem;
  display: inline-block;
  max-width: 150px; /* Ensure space for the X button */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-file-button {
  background: none;
  border: none;
  color: #ff4d4d;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
  margin-left: auto;
}

.remove-file-button:hover {
  color: #ff0000;
}


.hustlesmall-button {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #2563eb;
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hustlesmall-button:hover {
  background-color: #1e40af;
}


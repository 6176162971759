/* Container and general styling */
.listing-card-container {
  background-color: #1a202c;
  border: 1px solid #2d3748;
  border-radius: 0.75rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 20rem;
}

/* Image container */
.listing-card-image {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

.listing-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.listing-card-img:hover {
  transform: scale(1.05);
}

/* Trending icon */
.listing-card-trending-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  filter: drop-shadow(0 0 8px yellow);
}

/* Info container */
.listing-card-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Title and username */
.listing-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #f6e05e;
  text-decoration: none;
   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); /* Stronger text shadow */

}

.listing-card-title:hover {
  text-decoration: underline;
}

.listing-card-username {
  font-size: 1rem;
  color: #ecc94b;
  margin-bottom: 0.5rem;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); /* Stronger text shadow */

}

.listing-card-username:hover {
  text-decoration: underline;
}

/* Description and Price */
.listing-card-description {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.listing-card-price {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Rating */
.listing-card-rating {
  font-size: 0.875rem;
}

/* Button container */
.listing-card-button {
  flex-shrink: 0;
}

.listing-card-link {
  display: block;
  width: 100%;
  text-align: center;
  background: linear-gradient(to right, #2d3748, #4a5568);
  color: white;
  padding: 1rem;
  border-radius: 0 0 0.75rem 0.75rem;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
}

.listing-card-link:hover {
  background: linear-gradient(to right, #4a5568, #2d3748);
}

/* Modal styling */
.listing-card-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.listing-card-modal-content {
  position: relative;
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
}

.listing-card-modal-img {
  max-width: 100%;
  max-height: 90vh;
  border-radius: 0.5rem;
}

.listing-card-modal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: #e53e3e;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
}


.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
}

.tabs button.active {
  background: #007bff;
  color: #fff;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

button {
  cursor: pointer;
}

.sorting {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.sorting label {
  margin-right: 10px;
  font-size: 1rem;
  color: #f5f5f5;
}

.sorting select {
  padding: 5px;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  background-color: #1e293b;
  color: #f5f5f5;
  outline: none;
}


.careers {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.careers h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.careers p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

